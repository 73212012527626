//Specific JS for this skin
require(['jquery'], function ($) {
    $('#mini-cart .hide-below-960').html("<i class='fa fa-shopping-cart'></i>");
    $('.form-search .button-search').html("<i class='fa fa-search'></i>");

    if ($(".module-header-multi").length > 0) {
        if ($(".nav-search").length === 0) {
            $('.module-header-multi').append("<div class='show-below-960 item item-right nav-search'>" + $(".search-wrapper").html() + "</div>");
        }
    }


    $(".nav-search .form-search .button.button-search").on("click", function (e) {
        if (!$(".nav-search").hasClass("fly-out")) {
            e.preventDefault();
            $(".nav-search").addClass("fly-out");
            $(".nav-search .form-search .input-text").focus();
            $(".nav-search .form-search .button.button-search").after("<button class='button button-close'><i class='fa fa-times'></i></button>");
        }
    });

    $(document).on('click', '.nav-search .button-close', function (e) {
        e.preventDefault();
        $(".nav-search").removeClass("fly-out");
    });
});
